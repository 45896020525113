import React, { Component } from 'react';
import apiInstance from '../apiInstance';
import PopupModal from './popups/PopupModal';
import Button from '../Button';

class DeavadminProducts extends Component {

    constructor(props) {
        super(props);
        this.state = {
            availableWidgets: [],
            popupModal: React.createRef(),
            error: '',
        };

    }

    componentDidMount() {
        apiInstance.get('/devadmin/availableWidget')
        .then(response => {
            const availableWidgets = response.data.availableWidgets;
            this.setState((prevState) => {
                return {
                    ...prevState,
                    availableWidgets: availableWidgets
                }
            });
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
    }

    addAvailableWidget = (widget) => {

        this.setState((prevState) => {

            const updatedAvailableWidgets = [
                ...this.state.availableWidgets,
                widget
            ]

            return {
                ...prevState,
                availableWidgets: updatedAvailableWidgets
            }
        })
    }

    updateAvailableWidget = (updatedWidget) => {
        this.setState((prevState) => {
            const updatedAvailableWidgets = prevState.availableWidgets.map(widget => 
                widget.id === updatedWidget.id ? updatedWidget : widget
            );
            
            return {
                ...prevState,
                availableWidgets: updatedAvailableWidgets
            };
        });
    };

    deleteAvailableWidget = (widgetId) => {
        this.setState((prevState) => {
            const updatedAvailableWidgets = prevState.availableWidgets.filter(widget => widget.id !== widgetId);
            
            return {
                ...prevState,
                availableWidgets: updatedAvailableWidgets
            };
        });
    };
    

    render() {
        const availableWidgets = this.state.availableWidgets;

        return(
            <div className='mjwidev-main'>
                <div className='mjwidev-main-top'>
                    <h2>Our Products</h2>
                    <div className='mjwidev-add-avl-widget-btn mjwi-btn mjwidev-addnew' onClick={() => this.state.popupModal.current.openPopup("addAvailableWidget")}>Add New Product</div>
                </div>
                <div className='mjwi-ct-list mjwi-row'>
               {
                    availableWidgets.map((widget) => {
                        return (
                        <div key={widget.id} className="mjwi-ct-card">
                            <div className="mjwi-ct-card-banner">
                                <img src={"/images/" + widget.name + '-banner.png'} className="mjwi-catalog-banner" />
                            </div>
                            <div className="mjwi-ct-card-logoname">
                                <img src={"/images/" + widget.name + '-logo.png'} className="mjwi-catalog-logo" />
                                <h3 className="mjwi-ct-card__title">{widget.name} <span>${widget.price}</span></h3>
                            </div>
                            <div className="mjwi-ct-card-desc">
                                <p>Category: <span>{widget.category}</span></p>
                                <p>Stripe Price ID: <span>{widget.stripePriceId}</span></p>
                                <p>Stripe Product ID: <span>{widget.stripeProductId}</span></p>
                                <p>Main script: <span>{widget.scripts.main}</span></p>
                                <p>Lib script: <span>{widget.scripts.lib}</span></p>
                            </div>
                            <div className="mjwi-ct-card-btns">
                                <Button name="Edit" eventHandler={() => this.state.popupModal.current.openPopup("updateAvailableWidget", {widget: widget})} />
                                <Button name="Delete" eventHandler={() => this.state.popupModal.current.openPopup("deleteAvailableWidget", {availableWidgetId: widget.id})} />
                            </div>
                        </div>
                        )
                    })
               }
                </div>
               <PopupModal
                    ref={this.state.popupModal}
                    addAvailableWidget={this.addAvailableWidget}
                    updateAvailableWidget={this.updateAvailableWidget}
                    deleteAvailableWidget={this.deleteAvailableWidget}
                />

            </div>
        )

    }



}
export default DeavadminProducts;