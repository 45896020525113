import React, { Component } from 'react';
import apiInstance from '../apiInstance';
import PopupModal from './popups/PopupModal';
import { connect } from 'react-redux';
import {changeCurrentUser} from '../../state/actionCreators/index'

class DeavadminUsers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            users: [],
            popupModal: React.createRef(),
            error: '',
        };

    }

    componentDidMount() {
        apiInstance.get('/devadmin/user')
        .then(response => {
            const users = response.data.users;
            this.setState((prevState) => {
                return {
                    ...prevState,
                    users: users
                }
            });
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
    }

    addUser = (user) => {

        this.setState((prevState) => {

            const updatedUsers = [
                ...this.state.users,
                user
            ]

            return {
                ...prevState,
                users: updatedUsers
            }
        })
    }

    updateUser = (updatedUser) => {
        this.setState((prevState) => {
            const updatedUsers = prevState.users.map(user => 
                user.id === updatedUser.id ? updatedUser : user
            );
            
            return {
                ...prevState,
                users: updatedUsers
            };
        });
    };

    deleteUser = (userId) => {
        this.setState((prevState) => {
            const updatedUsers = prevState.users.filter(user => user.id !== userId);
            
            return {
                ...prevState,
                users: updatedUsers
            };
        });
    };

    switchUser = (userId) => {
        apiInstance.get(`/devadmin/user/${userId}`)
        .then(response => {
            const user = response.data.user;

            const adminId = localStorage.getItem("userId");
            const adminToken = localStorage.getItem("token");

            localStorage.setItem('token', user.token);
            localStorage.setItem('userId', user.id);

            sessionStorage.setItem('adminToken', adminToken);
            sessionStorage.setItem('adminId', adminId);
            sessionStorage.setItem('userSwitched', true);

            this.props.changeCurrentUser(user)
            window.location.href = "/home";
            
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
    }
    

    render() {
        const users = this.state.users;

        return(
            <div className='mjwidev-main'>
                <div className='mjwidev-main-top'>
                    <h2>Our Orders</h2>
                    <div className='mjwidev-add-avl-widget-btn mjwi-btn mjwidev-addnew' onClick={() => this.state.popupModal.current.openPopup("addAvailableWidget")}>Add Manual Order</div>
                </div>
                This is Order List

            </div>
        )

    }



}

const mapStateToProps = (state) => {
    return (
        {
            user: state.currentUser,
        }
    )
}
const mapDispatchToProps = (dispatch) => {
    return (
      {
        changeCurrentUser: (user) => { dispatch(changeCurrentUser(user)) },
      }
    )
  }
export default connect(mapStateToProps, mapDispatchToProps)(DeavadminUsers);