import React, { Component } from 'react';
import apiInstance from '../apiInstance';
import DevadminCollectionData from './DevadminCollectionData';
import { connect } from 'react-redux';
import {changeCurrentUser} from '../../state/actionCreators/index';
import DevadminDashboard from './DevadminDashboard';
import DevadminUsers from './DevadminUsers';
import DevadminOrders from './DevadminOrders';
import '../../assets/css/devadmin.css';
import DeavadminProducts from './DeavadminProducts';
import DeavadminWidgets from './DevadminWIdgets';

class Devadmin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            displayName: '',
            email: '',
            dbCollections: [],
            currentCollection: 'dashboard',
            documents: [],
            error: '',
        };
    }

    componentDidMount() {

        this.setState((prevState) => {
            return ({
                ...prevState,
                error: ""
            })
        })

        apiInstance.get('/devadmin')
            .then(response => {
                const dbCollections = response.data.collections;
                this.setState({
                    dbCollections: dbCollections
                });
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });

    }

    handleCollectionChange = (e) => {

        const currColl = e.target.getAttribute('data-id');

        this.setState((prevState) => {
            return ({
                ...prevState,
                currentCollection: currColl
            })
        })

        if(currColl === "payments") {

            var request_body = { collectionName: currColl };

            apiInstance.post('/devadmin/collection', request_body)
                .then(response => {
                    const documents = response.data.documents;
                    this.setState({
                        documents: documents
                    });
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });

        }

    }

    render() {
        let { error, dbCollections, currentCollection, documents } = this.state;
        const collections = dbCollections.map((item, i) => { return <p key={i} data-id={item} onClick={this.handleCollectionChange}>{item}</p> });

        return (

            <div className='mjwidev-container'>
                <div className='mjwidev-container-left'>
                    <ul>
                        <li className={currentCollection === 'dashboard' ? 'dvactive' : ''} data-id='dashboard' onClick={this.handleCollectionChange}><i class="fa-solid fa-house"></i> Dashboard</li>
                        <li className={currentCollection === 'products' ? 'dvactive' : ''} data-id='products' onClick={this.handleCollectionChange}><i class="fa-solid fa-box-archive"></i> Products</li>
                        <li className={currentCollection === 'users' ? 'dvactive' : ''} data-id='users' onClick={this.handleCollectionChange}><i class="fa-solid fa-user"></i> Users</li>
                        <li className={currentCollection === 'orders' ? 'dvactive' : ''} data-id='orders' onClick={this.handleCollectionChange}><i class="fa-solid fa-store"></i> Orders</li>
                        <li className={currentCollection === 'payments' ? 'dvactive' : ''} data-id='payments' onClick={this.handleCollectionChange}><i class="fa-solid fa-sack-dollar"></i> Payments</li>
                    </ul>
                </div>

                <div className='mjwidev-container-right'>
                    { currentCollection === 'dashboard' && <DevadminDashboard/> }
                    { currentCollection === 'products' && <DeavadminProducts/> }
                    { currentCollection === 'users' && <DevadminUsers/> }
                    { currentCollection === 'orders' && <DevadminOrders/> }
                    { currentCollection === 'payments' && <DevadminCollectionData documents={documents}/> }
                </div>
            </div>
        );
    }


} // class

const mapStateToProps = (state) => {
    return (
        {
            user: state.currentUser,
        }
    )
}
const mapDispatchToProps = (dispatch) => {
    return (
      {
        changeCurrentUser: (user) => { dispatch(changeCurrentUser(user)) },
      }
    )
  }
export default connect(mapStateToProps, mapDispatchToProps)(Devadmin);