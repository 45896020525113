import React, { act, Component } from 'react';
import { changeOverlayStatus } from '../../../state/actionCreators/index';
import { connect } from 'react-redux';
import DeletePopup from './DeletePopup';
import Button from '../../Button';
import AddUserPopup from './AddUserPopup';
import AddWidgetPopup from './AddWidgetPopup';

class PopupModal extends Component {
    constructor(props) {
        super(props);
        this.state = {

            displayPopup: false,
            action: '',
            data: {},
            error: ''
        };
    }

    openPopup = (action, data = {}) => {
        this.setState((prevState) => {
            return ({
                ...prevState,
                displayPopup: true,
                action: action,
                data: data,
                error: ''
            })
        })
        this.props.changeOverlayStatus('true');
    }

    closePopup = () => {
        this.setState((prevState) => {
            return ({
                ...prevState,
                displayPopup: false,
                action: '',
                data: {},
                error: ''
            })
        })
        this.props.changeOverlayStatus('false');
    }

    updateError = (error) => {
        
        this.setState((prevState) => {
            return ({
                ...prevState,
                error: error
            })
        })
    }

    render () {

        let {displayPopup, action, data, error} = this.state;
        return (
            <div className={'mjwidev-popup ' + (displayPopup ? '' : ' mjwi-hidden')}>
                <div className='mjwidev-popup-inner'>
                    <span className="mjwi-popup-close" onClick={this.closePopup}>
                        <i className="fa-solid fa-xmark"></i>
                    </span>
                    {
                        action === 'deleteUser' && 
                        <DeletePopup 
                            data={data}
                            type="user"
                            deleteUser={this.props.deleteUser}
                            updateError={this.updateError}
                            closePopup={this.closePopup}
                        />
                    }

                    {
                        action === 'addUser' && 
                        <AddUserPopup 
                            data={data}
                            type="add"
                            addUser={this.props.addUser}
                            updateError={this.updateError}
                            closePopup={this.closePopup}
                        />
                    }

                    {
                        action === 'updateUser' && 
                        <AddUserPopup 
                            data={data}
                            type="update"
                            updateUser={this.props.updateUser} 
                            updateError={this.updateError}
                            closePopup={this.closePopup}
                        />
                    }

                    {
                        action === 'addAvailableWidget' && 
                        <AddWidgetPopup
                            data={data}
                            type="add"
                            addAvailableWidget={this.props.addAvailableWidget}
                            updateError={this.updateError}
                            closePopup={this.closePopup}
                        />
                    }

                    {
                        action === 'updateAvailableWidget' && 
                        <AddWidgetPopup 
                            data={data}
                            type="update"
                            updateAvailableWidget={this.props.updateAvailableWidget} 
                            updateError={this.updateError}
                            closePopup={this.closePopup}
                        />
                    }

                    {
                        action === 'deleteAvailableWidget' && 
                        <DeletePopup 
                            data={data}
                            type="availableWidget"
                            deleteAvailableWidget={this.props.deleteAvailableWidget}
                            updateError={this.updateError}
                            closePopup={this.closePopup}
                        />
                    }

                    {
                        action === 'deleteWidget' && 
                        <DeletePopup 
                            data={data}
                            type="widget"
                            deleteWidget={this.props.deleteWidget}
                            updateError={this.updateError}
                            closePopup={this.closePopup}
                        />
                    }

                    
                    <div className={'mjwi-error' + (error ? '' : ' mjwi-hidden')}>{error}</div>

                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return (
        {
            overlay: state.overlay,
        }
    )
  }
  
  const mapDispatchToProps = (dispatch) => {
    return (
        {
            changeOverlayStatus: (status) => {dispatch(changeOverlayStatus(status))},
        }
    )
  }
  
  export default connect(mapStateToProps,mapDispatchToProps,null, {forwardRef: true} )(PopupModal);
  